import _ from "lodash"
import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"
import FlexCard from "./card"

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 50px 40px;

  text-align: center;

  > svg {
    width: 100px;
  }

  > h4 {
    margin: 25px 0 10px;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-align: center;
    max-width: 100%;
  }

  > p {
    margin: 0;
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 24px;
    max-width: 100%;
    color: ${props => props.theme.text_color || colors.offBlack};
  }

  color: ${props => props.theme.color || colors.blue};
`

const ImageWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  align-items: center;
  justify-content: center;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const makeTextIconCard = ({
  title,
  description,
  icon,
  theme,
  image,
  ...rest
}) => {
  return (
    <FlexCard theme={theme} {...rest}>
      <ContentWrapper theme={theme}>
        <img src={_.get(image, "0.url")} alt={title} />
        <h4>{title}</h4>
        <p>{description}</p>
      </ContentWrapper>
    </FlexCard>
  )
}

export const makeImageCard = ({ image, theme, title, ...rest }) => {
  return (
    <FlexCard theme={theme} {...rest}>
      <ImageWrapper>
        <img src={_.get(image, "0.url")} alt={title} />
      </ImageWrapper>
    </FlexCard>
  )
}
