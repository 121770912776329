import React from "react"
import styled, { css } from "styled-components"

import below from "../../styles/media"
import { getIcon } from "../Icons"
import Tags, { TagsContainer } from "../Tags"

export const CaseHeaderWrapper = styled.div`
  background-color: ${props => props.bg_color};
  background-image: ${({ bg_img }) => (bg_img ? `url(${bg_img})` : "none")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: ${props => props.text_color};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 60px;

  & > .icon {
    width: 80px;

    > img {
      width: 100%;
    }
  }

  & > h1 {
    font-size: 60px;
    font-weight: 600;
    text-align: center;
  }

  & > ${TagsContainer} {
    width: auto;
  }

  ${below.tablet`
    ${({ bg_img_mob }) =>
      bg_img_mob &&
      css`
        background-image: ${`url(${bg_img_mob})`};
      `}
  `}
`

export const CaseHeader = ({
  title,
  tags,
  icon,
  bg_color,
  color,
  icon_url,
  bg_img,
}) => {
  return (
    <CaseHeaderWrapper bg_img={bg_img} bg_color={bg_color} text_color={color}>
      <div className="icon">
        {icon_url ? <img src={icon_url} alt={title} /> : getIcon(icon)}
      </div>
      <h1>{title}</h1>
      <Tags tags={tags} />
    </CaseHeaderWrapper>
  )
}

export default CaseHeader
