import styled, { css } from "styled-components"

import colors from "../../styles/colors"
import below from "../../styles/media"

/**
 * Wrapper element for flex rows
 * Auto-sized by `card_size` property
 *
 * @param theme [object] Theme object to style card
 */
export const FlexCard = styled.div`
  background: ${props => props.theme.background};

  ${({ is_callout }) =>
    is_callout &&
    css`
      margin: 0 10px;
      ${below.tablet`
        margin-right: 10px;
      `};
    `}
  ${({ is_callout }) =>
    is_callout &&
    css`
      margin: 0 10px;
      ${below.tablet`
        margin-right: 10px;
      `};
    `}

  margin-bottom: 20px;

  border-radius: 0px;
  transition: border-radius 0.6s ease;
  overflow: hidden;

  &:hover {
    border-radius: 10px;
  }

  color: ${colors.white};

  background-color: ${props => props.theme.background || colors.white};

  ${below.desktop`

  `}
  ${below.tablet`
    margin-bottom: 10px;
  `};
`

export default FlexCard
