import _ from "lodash"
import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"
import below from "../../styles/media"
import QuoteIcon from "../Icons/Quote"

const QuoteContainer = styled.div`
  width: 100%;
  padding-top: 50px;

  display: flex;
  flex-direction: column;

  > div {
    &:last-child {
      z-index: 1;
    }
  }
`

const QuoteContent = styled.div`
  background-color: ${colors.white};

  border-radius: 0 0 5px 5px;
  box-shadow: #00000019 0 10px 10px 0;
  padding: 0 70px 40px;

  > img {
    width: ${props => props.logo_size};
    margin: 0 auto;
    display: block;
  }

  p {
    text-align: center;
    color: ${colors.darkGrey};
    font-size: 22px;
    margin: 0;

    &.quote {
      line-height: 36px;
      margin: 30px 0 20px;
    }
    &.quoteBy {
      font-weight: 600;
      margin: 20px 0 10px;
    }
    &.position {
      font-size: 16px;
      font-weight: 300;
    }
    ${below.phone`
      font-size: 16px;
    `}
  }

  ${below.tablet`
    padding: 0 40px 20px;
  `}

  ${below.phone`
    padding: 0 10px 10px;
  `}
`

const QuoteHeadBlock = styled.div`
  display: flex;

  > div {
    position: relative;
    z-index: 0;
    flex: 1 0 0px;
    box-shadow: #00000019 0 10px 10px 0;
    height: 72px;
    background-color: ${colors.white};

    &:first-child {
      border-radius: 5px 0 0 0;
    }
    &:last-child {
      border-radius: 0 5px 0 0;
    }

    &.centreBlock {
      flex: 0 0 auto;
      z-index: 1;
      box-shadow: none;
      width: 144px;
      background: radial-gradient(
        circle at top,
        transparent,
        transparent 72px,
        ${colors.white} 73px
      );

      > svg {
        position: absolute;
        left: 50%;
        top: -25px;
        transform: translate(-50%, 0);
        width: 82px;
      }
    }
  }
`

export const Quote = props => {
  const { quoteText, person, title, logo, logo_size = "64px" } = props
  return (
    <QuoteContainer>
      <QuoteHeadBlock>
        <div />
        <div className="centreBlock">
          <QuoteIcon />
        </div>
        <div />
      </QuoteHeadBlock>
      <QuoteContent logo_size={logo_size} className='quote-content'>
        <p className="quote">{quoteText}</p>
        {!_.isNil(logo) && <img src={logo} alt="Logo" />}
        <p className="quoteBy">{person}</p>
        {!_.isNil(title) && <p className="position">{title}</p>}
      </QuoteContent>
    </QuoteContainer>
  )
}

export default Quote
