import React from "react"
import styled, { css } from "styled-components"

import colors from "../../styles/colors"

const SlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 13px 0;

  background-color: ${colors.lightGrey};

  h4 {
    color: ${colors.strongBlack};
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 35px;
    text-align: center;
    margin: 0;
  }
  p {
    color: ${colors.offBlack};
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 23px;
    text-align: center;

    max-width: 550px;
  }

  .contentWrapper {
    opacity: 1;
    max-height: 600px;
    overflow: hidden;

    transition: opacity 0.4s 0.1s, max-height 0.8s;

    p {
      transition: margin 0s;
    }
  }

  ${props =>
    props.closed &&
    css`
      .contentWrapper {
        max-height: 0px;
        opacity: 0;
        transition: opacity 0.4s, max-height 0.4s;
        p {
          margin: 0;
          transition: margin 0s 0.4s;
        }
      }
    `}
`

const ToggleButton = styled.div`
  cursor: pointer;
  height: 47px;
  width: 47px;

  position: relative;

  > .backlight {
    border-radius: 5px;
    background-color: #c2c2c2;
    z-index: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  &:hover > .backlight {
    opacity: 0.2;
  }

  > .vertical,
  > .horizontal {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;

    background-color: ${colors.strongBlack};

    ${props =>
      props.closed
        ? css`
            transform: translate3d(-50%, -50%, 0);
            transition: transform 0.3s ease;
          `
        : css`
            transform: translate3d(-50%, -50%, 0) rotate(45deg);
            transition: transform 0.3s ease 0.2s;
          `};
  }

  > .vertical {
    height: 21px;
    width: 2px;
  }
  > .horizontal {
    height: 2px;
    width: 21px;
  }
`

export class SlideTitle extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      closed: false,
    }
    this.toggle = this.toggle.bind(this)
  }
  toggle() {
    this.setState(state => ({ closed: !state.closed }))
  }
  render() {
    const { title, description } = this.props
    return (
      <SlideWrapper closed={this.state.closed}>
        <div className="contentWrapper">
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
        <ToggleButton closed={this.state.closed} onClick={this.toggle}>
          <div className="vertical" />
          <div className="horizontal" />
          <div className="backlight" />
        </ToggleButton>
      </SlideWrapper>
    )
  }
}

export default SlideTitle
