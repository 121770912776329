import _ from "lodash"
import React from "react"

import { screen_sizes } from "../../styles/media"
import ShowMore from "../ShowMore"
import Spacer from "../Spacer"
import FlexCard from "./card"
import { makeImageCard, makeTextIconCard } from "./cards"
import {
  CalloutWrapper,
  ColumnsWrapper,
  ContentWrapper,
  FlexColumn,
  RightColumns,
} from "./layout"

export * from "./cards"
export * from "./card"
export * from "./layout"

export const getCard = (type, props) => {
  switch (type) {
    case "text-with-icon":
      return makeTextIconCard(props)
    case "image":
      return makeImageCard(props)
    default:
      return (
        <FlexCard {...props}>
          <h1>Width: {props.card_size}</h1>
          <h2>Original order: {props.key}</h2>
          <p>Type: {type}</p>
          <p>Props: {JSON.stringify(props, null, 1)}</p>
        </FlexCard>
      )
  }
}

export class FlexColumnController extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      max_cols: 3,
      show_all: false,
    }

    _.bindAll(this, [
      "renderCard",
      "renderColumns",
      "renderColumn",
      "handleResize",
    ])
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
  }

  get has_more_cards() {
    if (this.state.show_all) {
      return false
    }
    const cards = _.get(this.props, "cards", [])
    const hidden = _.filter(cards, card => !_.get(card, "startShown", false))
    return !_.isEmpty(hidden)
  }

  handleResize() {
    const width = document.documentElement.clientWidth
    if (width > screen_sizes.desktop) {
      this.setState({
        max_cols: 3,
      })
    } else if (width > screen_sizes.tablet) {
      this.setState({
        max_cols: 2,
      })
    } else {
      this.setState({
        max_cols: 1,
      })
    }
  }

  get raw_cols() {
    const cards = _.get(this.props, "cards", [])
    const columns = _.groupBy(
      _(cards)
        .orderBy("weight", "desc")
        .filter(
          card =>
            _.get(card, "startShown") ||
            _.get(card, "column") === "callout" ||
            this.state.show_all
        )
        .value(),
      "column"
    )

    return columns
  }

  get columns() {
    const columns = this.raw_cols

    if (this.state.max_cols === 3) {
      return [
        _.get(columns, "1", []),
        _.get(columns, "2", []),
        _.get(columns, "3", []),
      ]
    } else if (this.state.max_cols === 2) {
      /**
       * If there can only be two columns, distribute the cards from col 1
       * between column 2 and 3
       */
      const cols = [_.get(columns, "2", []), _.get(columns, "3", [])]
      // Because of callout card, put first card in second column
      let i = 1
      _.forEach(_.get(columns, "1", []), col => {
        cols[i].push(col)
        i = (i + 1) % 2 // Swap between 1st and 2nd column
      })

      return [[], ..._.map(cols, c => _.orderBy(c, "weight", "desc"))]
    } else {
      /**
       * If there can only be one column, put all the cards in a single column
       * Use the middle column so that callout remains on top
       */
      const cols = [
        [],
        [
          ..._.get(columns, "1", []),
          ..._.get(columns, "2", []),
          ..._.get(columns, "3", []),
        ],
      ]

      return _.orderBy(cols, "weight", "desc")
    }
  }

  renderCard(card, i, is_callout = false) {
    const type = _.get(card, "type")
    const props = {
      ...card,
      key: i,
      is_callout,
    }
    return getCard(type, props)
  }

  renderColumn(columns, col) {
    return (
      <FlexColumn column_number={col}>
        {_.map(_.get(columns, col, []), (c, i) => this.renderCard(c, i))}
      </FlexColumn>
    )
  }

  renderCallout() {
    const callout_card = _.get(this.raw_cols, "callout.0")

    if (!_.isEmpty(callout_card)) {
      return this.renderCard(callout_card, 0, true)
    }

    return null
  }

  renderColumns() {
    const columns = this.columns

    return (
      <React.Fragment>
        {this.renderColumn(columns, 0)}
        <CalloutWrapper>
          {this.renderCallout()}
          <RightColumns>
            {this.renderColumn(columns, 1)}
            {this.renderColumn(columns, 2)}
          </RightColumns>
        </CalloutWrapper>
      </React.Fragment>
    )
  }

  render() {
    return (
      <ContentWrapper>
        <ColumnsWrapper>{this.renderColumns()}</ColumnsWrapper>
        {this.has_more_cards && (
          <React.Fragment>
            <Spacer height={60} />
            <ShowMore onClick={() => this.setState({ show_all: true })} />
          </React.Fragment>
        )}
      </ContentWrapper>
    )
  }
}

export default FlexColumnController
