import styled, { css } from "styled-components"

import below from "../../styles/media"

export const FlexColumn = styled.div`
  position: relative;
  flex: 1 0 0px;

  margin: 0px 10px;

  ${below.desktop`
    ${({ column_number }) =>
      column_number === 0 &&
      css`
        display: none;
      `}

  `}

  ${below.tablet`
    ${({ column_number }) =>
      column_number !== 1 &&
      css`
        display: none;
      `}
  `}
`

export const CalloutWrapper = styled.div`
  flex: 2 0 0px;
  display: flex;
  flex-direction: column;
  ${below.desktop`
    flex: 1 0 0px;
    margin: 0 0 0 10px;
  `}
`

export const RightColumns = styled.div`
  display: flex;
  flex-direction: row;
`

export const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 60px 10px 0;

  ${below.tablet`
    margin: 30px 0 0;
  `}
`

export const ContentWrapper = styled.div`
  .showMore {
    margin: 0 auto;
  }
`
