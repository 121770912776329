import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"

export const StickyWrapper = styled.div`
  background-color: ${colors.stickyLink};
  height: 50px;
  width: 100%;
  color: ${colors.white};

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.8px;

  position: sticky;
  bottom: 0;
  z-index: 101;

  > a {
    color: ${colors.blue};
    text-decoration: underline;
    margin-left: 5px;
  }
`

export const StickyLink = ({ text, location }) => {
  return (
    <StickyWrapper>
      {text} {" "}
      <a href={location} target="_blank" rel="noopener noreferrer">
        {location}
      </a>
    </StickyWrapper>
  )
}

export default StickyLink
