import { graphql } from "gatsby"
import _ from "lodash"
import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"

import { CaseStudyCard } from "../components/Card/types"
import CaseHeader from "../components/CaseHeader"
import FlexColumnController from "../components/FlexCols"
import ItemRows from "../components/ItemRows"
import { PageContentsWrapper, PageLayout } from "../components/PageLayout"
import Quote from "../components/Quote"
import Subtitle from "../components/SectionTitle/subtitle"
import SlideTitle from "../components/SlideTitle"
import Spacer from "../components/Spacer"
import StickyLink from "../components/StickyLink"
import {
  createStaticContent,
  makeStaticPageTitle,
  transformToArray,
  transformToMap,
} from "../data/transform"
import colors from "../styles/colors"
import { makeTags } from "../utils/caseStudy"

export const query = graphql`
  query($caseStudy: String!, $recordId: String!) {
    locations: allAirtable(filter: { table: { eq: "Locations" } }) {
      ...LocationsFragment
    }
    caseStudy: airtable(
      table: { eq: "CaseStudies" }
      data: { case: { eq: $caseStudy } }
    ) {
      data {
        case
        name
        productID
        clientID
        sportID
        weight
        homepage
        link
        linkText
        subtitle
        description
        quoteID
        similar
        cardImage {
          url
        }
        bannerImage {
          url
        }
        bannerLogo {
          url
        }
        bannerColour
      }
    }
    sports: allAirtable(filter: { table: { eq: "Sports" } }) {
      ...SportsFragment
    }
    clients: allAirtable(filter: { table: { eq: "Clients" } }) {
      ...ClientsFragment
    }
    products: allAirtable(filter: { table: { eq: "Products" } }) {
      ...ProductsFragment
    }
    caseStudies: allAirtable(
      filter: { table: { eq: "CaseStudies" }, data: { Disable: { ne: true } } }
    ) {
      ...CaseStudiesFragment
    }
    cards: allAirtable(
      filter: {
        table: { eq: "CaseStudyCards" }
        data: { caseStudy: { eq: $recordId }, Disable: { ne: true } }
      }
    ) {
      ...CaseStudyCardsFragment
    }
    quotes: allAirtable(
      filter: {
        table: { eq: "Quotes" }
        data: { CaseStudies: { eq: $recordId } }
      }
    ) {
      ...QuotesFragment
    }
    static_content_all: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "all" } } }
    ) {
      ...StaticContentFragment
    }
    static_content_case: allAirtable(
      filter: {
        table: { eq: "StaticContent" }
        data: { page: { eq: "case-study" } }
      }
    ) {
      ...StaticContentFragment
    }
  }
`

export const FlexCardContentWrapper = styled.div`
  margin: 0 auto 100px;
`

export const CaseStudyPage = props => {
  const locations = transformToArray(
    props.data,
    "locations",
    record => record.inFooter
  )

  const sportMap = transformToMap(props.data, "sports")
  const clientMap = transformToMap(props.data, "clients")
  const productMap = transformToMap(props.data, "products")
  const caseMap = transformToMap(props.data, "caseStudies")
  const quotesMap = transformToMap(props.data, "quotes")

  const caseStudy = props.data.caseStudy.data
  const cards = transformToArray(props.data, "cards")

  const similarCases = _(_.get(caseStudy, "similar", []))
    .map(recordId => _.get(caseMap, recordId))
    .filter(c => !_.isEmpty(c))
    .value()

  const client = _.get(clientMap, _.get(caseStudy, "clientID.0"))
  const quote = _.get(quotesMap, _.get(caseStudy, "quoteID.0"))

  const banner_img = _.get(caseStudy, "bannerImage.0.url")
  const client_logo = _.get(client, "logo.0.url")
  const banner_logo = _.get(caseStudy, "bannerLogo.0.url", client_logo)

  const content = createStaticContent(props.data, "case")
  const linkText = _.get(caseStudy, "linkText");
  console.log(linkText);

  return (
    <PageLayout
      bg={colors.offWhite}
      locations={locations}
      bottom_child={
        <StickyLink text={linkText} location={_.get(caseStudy, "link")} />
      }
      static_content={content}
    >
      <Helmet>
        <title>{makeStaticPageTitle(content, _.get(caseStudy, "name"))}</title>
      </Helmet>
      <CaseHeader
        title={_.get(caseStudy, "name", "")}
        tags={makeTags(caseStudy, sportMap, productMap, clientMap)}
        icon_url={banner_logo}
        bg_color={_.get(caseStudy, "bannerColour", "#DC1B21")}
        color={colors.white}
        bg_img={banner_img}
      />
      <SlideTitle
        title={_.get(caseStudy, "subtitle", "")}
        description={_.get(caseStudy, "description", "")}
      />
      <FlexCardContentWrapper>
        <FlexColumnController cards={cards} />
      </FlexCardContentWrapper>
      <PageContentsWrapper>
        {quote && (
          <React.Fragment>
            <Quote {...quote} logo={_.get(client, "logo.0.url")} />
            <Spacer height={100} />
          </React.Fragment>
        )}
        <Subtitle title={"Similar Projects"} />
        <ItemRows child_limit={0}>
          {_.map(similarCases, (caseStudy, i) => (
            <CaseStudyCard
              key={i}
              caseStudy={caseStudy}
              clientMap={clientMap}
              productMap={productMap}
              sportMap={sportMap}
            />
          ))}
        </ItemRows>
      </PageContentsWrapper>
    </PageLayout>
  )
}

export default CaseStudyPage
